export default {
    //AI创作-主题列表
    GET_AITHEME_LIST: '/api/aiCreate/theme/list',
    //AI创作-主题创建
    CREATE: '/api/aiCreate/theme/create',
    CANCEL_CREATE: '/api/aiCreate/theme/cancel',
    //根据主题ID获取生成时的配置数据
    GET_THEME_BYID: '/api/aiCreate/theme/info',
    //AI创作-主题对应文章列表
    GET_AIARTICLE_LIST: '/api/aiCreate/articleByTheme/list',
    //AI创作-管理端批量下发文章
    SET_AITHEME_RELEASE: '/api/aiCreate/theme/release',
    //AI创作-管理端批量发布文章
    SET_AITHEME_PUBLISH: '/api/aiCreate/theme/autosend',
    //AI创作-发布记录
    GET_AIPUBLISH_LIST: '/api/aiCreate/article/list',
    //发布记录导出
    EXPORT_AIPUBLISH_LIST: '/api/export/aiCreate/article/list',
    //AI创作-编辑文章
    UPDATE_AIARTICLE: '/api/aiCreate/article/update',
    //AI润色-大模型二次润色
    POLISH_AIARTICLE: '/api/aicreate/articleByTheme/polish',

    //批量替换主题对应文章的标题和内容
    REPLACE_ARTICLE_TEXT: '/api/aiCreate/articleByTheme/replace',
    //批量修改主题对应文章的标题
    UPDATE_ARTICLE_TITLE: '/api/aiCreate/articleByTheme/title/update',
    //批量替换主题对应文章的标题（非对称-已弃用）
    REPLACE_ARTICLE_TITLE: '/api/aiCreate/articleByTheme/title/replace',
    GET_ARTILE_TITLE_LIST: '/api/aiCreate/articleByTheme/title/list',
    GET_AI_TITLEAUTO_LIST: '/api/aicreate/articleByTheme/aiauto/list',
    AI_TITLEAUTO_START: '/api/aiCreate/articleByTheme/title/aiauto',

    //Ai创作-生成失败
    REFRESH_AIARTICLE: '/api/aiCreate/theme/refresh',

    //AI创作-账户中心
    GET_ACCOUNT_LIST: '/api/aiCreate/account/list',
    //导出
    GET_ACCOUNT_EXPORT: '/api/export/aiCreate/account/list',
    //数据同步
    ACCOUNT_SYNC: '/api/aiCreate/account/statistic/sync',
    //AI创作-账户创建
    CREATE_ACCOUNT: '/api/aiCreate/account/create',
    //AI创作-账户登录
    // ACCOUNT_LOGIN: '/api/aiCreate/account/login',
    //更新账户
    ACCOUNT_UPDATE: '/api/aiCreate/account/update',
    //删除账户
    ACCOUNT_DELETE: '/api/aiCreate/account/delete',
    //获取账户详情
    ACCOUNT_DETAIL: '/api/aiCreate/account/info',

    //智能分析-数据接口
    ANALYSIS_DATA: '/api/aiCreate/analysis/index',
    //顾问排行
    ADVISOR_RANK: '/api/aiCreate/analysis/consultantRank',
    //文章排行
    ARTICLE_RANK: '/api/aiCreate/analysis/articleRank',
    //趋势分析
    // TREND_CHART: '/api/aiCreate/analysis/trendchart',
    //增量趋势分析
    TREND_CHART: '/api/aiCreate/analysis/trendchart/growth',
    //总量趋势分析
    TREND_CHART_TOTAL: '/api/aiCreate/analysis/trendchart/cumulative',

    //主题删除或恢复
    RECOVER_AIARTICLE_THEME: '/api/aiCreate/theme/delete',

    //复制-创建主题副本
    CLONE_AIARTICLE_THEME: '/api/aiCreate/theme/copy',

    //获取AI图文发布配置
    AI_ARTICLE_PUBLISH_CONFIG: '/api/aiCreate/article/config',
    //更新AI图文发布配置
    AI_ARTICLE_PUBLISH_CONFIG_UPDATE: '/api/aiCreate/article/config/update',
    //修改主题发布时效-定时发布时间
    AI_ARTICLE_TIMING_UPDATE: '/api/aiCreate/theme/timing/update',

    //编辑修改后即时保存
    //ai创作-修改主题标题文本
    AI_ARTICLE_MAINTITLE: '/api/aiCreate/theme/maintitle/update',
    //ai创作-修改主题话题标签
    AI_ARTICLE_TOPIC: '/api/aiCreate/theme/topic/update',
    //ai创作-修改主题下发账号
    AI_ARTICLE_SENDUSER: '/api/aiCreate/theme/senduser/update',

  //账号分组
  accountGroup: {
    //获取分组列表 type 1图文 2视频
    GET_LIST: '/api/aiCreate/groupList',
    // 创建分组   media name type
    CREATE: '/api/aiCreate/storeGroup',
    // 更新分组
    UPDATE: '/api/aiCreate/storeGroup',
    // 删除图文分组
    DELETE_AI_CREATE_GROUP: '/api/aicreate/delGroup',
    // 删除视频分组
    DELETE_AI_VIDEO_GROUP: '/api/aivideos/delGroup',

    // AI图文组织架构多层级列表
    ACCOUNT_PUBLISH_SELECT: '/api/aiCreate/accountPublishSelect',
    // AI视频组织架构多层级列表
    AI_VIDEO_ACCOUNT_PUBLISH_SELECT: '/api/aivideos/account/publish/select',

    // 下拉选择分组列表
    GET_SELECT_LIST: '/api/aicreate/getGroupByStoreUser',
    // AI图文-账户绑定到分组
    AI_CREATE_BIND_GROUP: '/api/aiCreate/bingUserToGroup',
    // AI视频-账户绑定到分组
    AI_VIDEO_BIND_GROUP: '/api/aivideos/bingUserToGroup',

    // AI图文 创作中心-发布账号-按账号分组-获取多层级列表
    AI_CREATE_GROUP_USER_SELECT: '/api/aicreate/groupUserSelect',
    // AI视频 创作中心-发布账号-按账号分组-获取多层级列表
    AI_VIDEO_GROUP_USER_SELECT: '/api/aivideos/groupUserSelect',

  },
  //热点融合
  hotPointMerge: {
    //获取灵感库列表
    GET_INSPIRATION_LIST: '/api/aiCreate/hottopics/list',
    //生成话题
    CREATE_TOPIC: '/api/aiCreate/hottopics/merge',
    //取消生成话题
    TOPIC_CANCEL: '/api/aiCreate/hottopics/cancel',
    //获取话题生成结果
    GET_TOPIC_INFO: '/api/aiCreate/hottopics/prompt/list'
  }
}
