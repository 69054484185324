import axios from 'axios';
import _ from 'lodash';
import cookie from '@/lib/cookie';
import config from '@/config';

const request = {};

const requestService = axios.create({
  timeout: 60000
});

//
const { tokenKey } = config;
requestService.interceptors.request.use(_config => {
  const token = cookie.get(tokenKey);
//   console.log('token', token, _config.url, _config.params && _config.params.isTemptkstr);
  if(_config && (_config.url!='/api/bx/getAuthToken') && token) {
    _config.headers['Authorization'] = 'Bearer ' + token;
  }
  if(_config && (_config.url=='/api/bx/douyinMiniInfo') && _config.params && _config.params.isTemptkstr){
    _config.headers['Authorization'] = 'Bearer ' + _config.params.isTemptkstr;
    delete _config.params.isTemptkstr;
  }
  return _config;
});

const send = async (url, data, method) => {
  let err = null, res = null;
  const options = { url, method };
  if (method === 'get') {
    options.params = data;
  } else {
    options.data = data;
  }
  let response = null;
  try {
    response = await requestService(options);
  } catch (e) {
    err = e;
  }
  res = _.get(response, 'data');
  const status = _.get(res, 'status');
  const msg = _.get(res, 'msg');
  if (status !== 'y') { //请求失败
    msg && (err = msg);
  }
  return { err, res };
};

request.get = async (url, data) => {
  const { err, res } = await send(url, data, 'get');
  return { err, res };
};
request.post = async (url, data) => {
  const { err, res } = await send(url, data, 'post');
  return { err, res };
};

export default request;
