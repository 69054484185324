//AI视频模板
export default {
    //视频模板-新增模板包
    CREATE_AIVIDEO_TEMPLETE: '/api/aivideos/addAivideoTemplate',
    //视频模板-编辑模板包
    EDIT_AIVIDEO_TEMPLETE: '/api/aivideos/storeAivideoTemplate',
    //视频模板-获取模板包列表
    GET_AIVIDEO_TEMPLETE_LIST: '/api/aivideos/getTemplatePackageList',
    labelsANDstar: {
        //视频模板-获取模板包标签
        GET_AIVIDEO_TEMPLETE_LABELS: '/api/aivideos/getTemplateLabel',
        //视频模板-新增/更新模板包标签
        UPDATE_AIVIDEO_TEMPLETE_LABEL: '/api/aivideos/storeTemplateLabel',
        //视频模板-包收藏
        ADD_AIVIDEO_TEMPLETE_COLLECT: '/api/aivideos/addCollection',
        //视频模板-取消包收藏
        REMOVE_AIVIDEO_TEMPLETE_COLLECT: '/api/aivideos/removeCollection',
        //视频模板-批量设置标签
        SET_AIVIDEO_TEMPLETE_LABEL: '/api/aivideos/setTemplateLabel',
        //删除标签
        DEL_AIVIDEO_TEMPLETE_LABEL: '/api/aivideos/deleteTemplateLabel'
    },
    //视频模板-获取模板包详情
    GET_AIVIDEO_TEMPLETE_DETAIL: '/api/aivideos/getTemplatePackageDetail',
    //视频模板-删除模板包
    DEL_AIVIDEO_TEMPLETE: '/api/aivideos/delTemplatePackage',
    //视频模板-删除模板包内的某些模板
    DEL_AIVIDEO_TEMPLETE_INPACKAGE: '/api/aivideos/delTemplate',
    //视频模板-创建副本(另存为)
    SAVEAS_AIVIDEO_TEMPLETE: '/api/aivideos/saveTemplate',
    aitempletePublish: {
        //视频模板-模板包下发列表
        GET_AIVIDEO_TEMPLETE_PUBLISHLIST: '/api/aivideos/aivideoTemplatePublishList',
        //视频模板-模板包下发
        SET_AIVIDEO_TEMPLETE_PUBLISH: '/api/aivideos/aivideoTemlpatePublish',
        //视频模板-编辑下发记录
        UPDATE_AIVIDEO_TEMPLETE_PUBLISH: '/api/aivideos/templatePublishUpdate',
        //视频模板-模板下发记录详情
        GET_AIVIDEO_TEMPLETE_PUBLISH_DETAIL: '/api/aivideos/templatePublishPreview',
        //视频模板-下发模板预览
        AIVIDEO_TEMPLETE_PUBLISH_PREVIEW: '/api/aivideos/templatePublishPreview',
    },

    //视频模板-获取贴图库
    GET_AIVIDEO_TEMPLETE_PIC: '/api/aivideos/getTemplatePic',
    //视频模板-删除贴图库-贴图
    DEL_AIVIDEO_TEMPLETE_PIC: '/api/aivideos/delTemplatePic',
    //视频模板-贴图上传(正常走图片上传接口)
    AIVIDEO_TEMPLETE_PIC_UPLOAD: '/api/admin/material/upload',

    //视频模板通过AI生成内容(异步)
    CREATE_AIVIDEO_TEMPLETE_CONTENT: '/api/aivideos/createAivideoTemplateContent',
    //视频模板-获取AI内容
    GET_AIVIDEO_TEMPLETE_CONTENT: '/api/aivideos/getAivideoTemplateContent',
    //视频模板-模板包内模板预览
    PREVIEW: '/api/aivideos/templatePreview',

    //视频模板-获取模板/模板包数量
    GET_TOTALTEMPS_NUMBER: '/api/aivideos/getTemplateNum',

    //视频模板-获取字体库
    GET_TEM_FONT: '/api/aivideos/getTemFont'
}
