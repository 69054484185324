
export default {
  //创作者账号
  authors: {
    //获取列表
    GET_LIST: '/api/bx/douyinCreatorsList',
    // 管理后台-导入
    EXCEL_IMPORT:'/api/bx/excelImport',
    // 管理后台-创作者账号列表-导出
    EXCEL_EXPORT:'/api/bx/excelExport',
  },
  //小程序列表
  smallProgram: {
    //抖音小程序列表/服务商小程序列表设置
    GET_LIST: '/api/bx/douyinMiniList',
    STORE_DOUYIN_MINI:'/api/bx/storeDouyinMini',
    //获取详情   单任务多顾问列表
    DELETE: '/api/admin/douyin/applet/delete',
    //管理后台-挂载小程序-获取小程序
    GET_MOUNT_APPLET: '/api/bx/getMountApplet',
    //管理后台-挂载/取消挂载
    MOUNT_APPLET: '/api/bx/mountApplet',
    IN_MOUNT_APPLET: '/api/bx/unmountApplet',
    //管理后台-下载导入模板
    EXCEL_UPLOAD: '/api/bx/excelUpload',
    // 小程序-表单提交
    APPLET_SUBMIT_CLUES: '/api/bx/appletSubmitClues',
    // 小程序-提交时获取地址
    GET_CITY_LIST: '/api/bx/getCityList?level=6&upid=37',
    // 小程序-获取小程序页面json
    GET_APPLET_PAGE: '/api/bx/getAppletPage?id=10',
    // H5-获取表单数据
    GET_CLUES_DATA: '/api/bx/getCluesData',
    //管理后台-提交审核
    MINI_SUBMIT_AUDIT: '/api/bx/miniSubmitAudit',
    // 管理后台-审核撤回
    REVOKE_AUDIT: '/api/bx/revokeAudit',
    //管理后台-小程序审核列表
    MINI_AUDIT_LIST: '/api/bx/miniAuditList',
    //管理后台-服务商小程序-打包代码
    MINI_SUBMIT_AUDIT_RECORD: '/api/bx/miniSubmitAuditRecord',
    // 管理后台-小程序设计-数据回填
    DOUYIN_MINI_INFO: '/api/bx/douyinMiniInfo',
    // 管理后台-小程序设计-保存
    SAVE_DOUYIN_MINI: '/api/bx/saveDouyinMini',
    // 管理后台-服务商小程序-授权
    GET_SERVICE_AUTH_LINK: '/api/bx/getServiceAuthLink',
    // 二维码
    GET_QR_CODE: '/api/bx/getQrCode',
    // 抖音小程序生成预览-获取token
    DOUYIN_MINI_PREVIEW_TEMPSTR: '/api/bx/getAuthToken',
  }
}
